import top from "../../images/top.jpg";

export default function PublicHeader() {
  return (
    <div className="relative">
      <img
        src={top}
        sizes="100vw"
        style={{
          objectFit: "cover",
          width: "100%",
          height: 350,
          objectPosition: "50% 75%",
        }}
        alt=""
      />
    </div>
  );
}
