import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import parseGeoraster from "georaster";
import { Button, Join, Select } from "react-daisyui";
import { addDays, getDay, getFullDate, getNextHour } from "helpers/utils";
import { mapValues } from "./MapColourValues";

export default function KlangMap(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [pollutantType, setPollutantType] = React.useState("NO2");
  const [date, setDate] = React.useState(getFullDate(new Date(2019, 3, 1)));
  const [time, setTime] = React.useState(getNextHour(new Date()));
  const [isMobile, setIsMobile] = React.useState(false);

  const [updateMap, setUpdateMap] = React.useState(false);
  const mapRef = React.useRef();

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const centerOfKlang = [3.045712928826625, 101.4449636037381];
  const pollutantSelection = [
    {
      text: (
        <div>
          NO<sub>2</sub>
        </div>
      ),
      value: "NO2",
    },
    {
      text: (
        <div>
          NO<sub>x</sub>
        </div>
      ),
      value: "NOx",
    },
    {
      text: (
        <div>
          O<sub>3</sub>
        </div>
      ),
      value: "O3",
    },
    {
      text: "PM 2.5",
      value: "PM2.5",
    },
    {
      text: "PM 10",
      value: "PM10",
    },
    {
      text: (
        <div>
          SO<sub>2</sub>
        </div>
      ),
      value: "SO2",
    },
    {
      text: "VOC",
      value: "VOC",
    },
  ];
  const outerBounds = [
    [3.2864965461236544, 101.09328524524926],
    [2.7851799420492678, 101.76801057705403],
  ];
  const dateSelection = [
    getFullDate(addDays(new Date(2019, 3, 1), 0)),
    getFullDate(addDays(new Date(2019, 3, 1), 1)),
    // getFullDate(addDays(new Date(), 0)),
    // getFullDate(addDays(new Date(), 1)),
    // getFullDate(addDays(new Date(), 2)),
  ];
  const timeSelection = [
    { text: "1.00 AM", value: "0100" },
    { text: "2.00 AM", value: "0200" },
    { text: "3.00 AM", value: "0300" },
    { text: "4.00 AM", value: "0400" },
    { text: "5.00 AM", value: "0500" },
    { text: "6.00 AM", value: "0600" },
    { text: "7.00 AM", value: "0700" },
    { text: "8.00 AM", value: "0800" },
    { text: "9.00 AM", value: "0900" },
    { text: "10.00 AM", value: "1000" },
    { text: "11.00 AM", value: "1100" },
    { text: "12.00 PM", value: "1200" },
    { text: "1.00 PM", value: "1300" },
    { text: "2.00 PM", value: "1400" },
    { text: "3.00 PM", value: "1500" },
    { text: "4.00 PM", value: "1600" },
    { text: "5.00 PM", value: "1700" },
    { text: "6.00 PM", value: "1800" },
    { text: "7.00 PM", value: "1900" },
    { text: "8.00 PM", value: "2000" },
    { text: "9.00 PM", value: "2100" },
    { text: "10.00 PM", value: "2200" },
    { text: "11.00 PM", value: "2300" },
  ];

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  async function fetchGeoTIFF() {
    try {
      const response = await fetch(`/${pollutantType}_${date}_${time}.tif`);
      const arrayBuffer = await response.arrayBuffer();
      const geoRaster = await parseGeoraster(arrayBuffer);

      if (mapRef.current) {
        const map = mapRef.current;
        var layer = new GeoRasterLayer({
          georaster: geoRaster,
          opacity: 0.5,
          resolution: 100,
          pixelValuesToColorFn: mapValues(pollutantType),
        });
        removeAllLayersExcept(layer);
        layer.addTo(map);

        function removeAllLayersExcept(new_layer) {
          var layer_index = 0;
          map.eachLayer(function (layer) {
            if (layer_index > 0 && layer._leaflet_id != new_layer._leaflet_id) {
              setTimeout(function () {
                // give a little time before removing previous tile layer because new one will appear with some transition.
                map.removeLayer(layer);
              }, 500);
            }
            layer_index++;
          });
        }
      }
    } catch (error) {
      console.error("Error loading GeoTIFF:", error);
    }
  }

  function renderPollutants() {
    return (
      <Join vertical>
        {pollutantSelection.map((pollutant, index) => {
          return (
            <Button
              key={`pollutant-button-${index}`}
              className={`join-item btn-sm md:btn-md ${pollutant.value === pollutantType ? "btn-active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setPollutantType(pollutant.value);
                setUpdateMap(true);
              }}
            >
              {pollutant.text}
            </Button>
          );
        })}
      </Join>
    );
  }

  function renderDays() {
    return (
      <Join
        // className="rounded-b-none rounded-r-none"
        className="rounded-r-none"
      >
        {dateSelection.map((date_, index) => {
          return (
            <Button
              key={`date-button-${index}`}
              className={`join-item ${date_ === date ? "btn-active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setDate(date_);
                setUpdateMap(true);
              }}
            >
              {getDay(addDays(new Date(), index), isMobile)}
            </Button>
          );
        })}
        <Select
          // className="rounded-l-none rounded-b-none border-none"
          className="rounded-l-none border-none"
          value={time}
          onChange={(e) => {
            e.preventDefault();
            setTime(e.target.value);
            setUpdateMap(true);
          }}
        >
          <option value={"default"} disabled>
            Pick a time
          </option>
          {timeSelection.map((value, index) => {
            return (
              <option key={`time-selection-${index}`} value={value.value}>
                {value.text}
              </option>
            );
          })}
        </Select>
      </Join>
    );
  }

  function renderLegend() {
    return (
      <div className="bg-gradient-to-r from-[#2b83ba] via-[#ffffbf] to-[#d7191c] flex justify-between p-2 px-4 font-extrabold text-white">
        <div className="">Good</div>
        <div className="">Bad</div>
      </div>
    );
  }

  React.useEffect(() => {
    setUpdateMap(true);
    if (props.isMobile && props.isMobile === true) {
      setIsMobile(true);
    }
  }, []);

  if (updateMap) {
    setUpdateMap(false);
    fetchGeoTIFF();
  }

  return (
    <div className={`${isMobile ? "h-[100vh]" : "h-[80vh]"} flex flex-col`}>
      <MapContainer
        center={centerOfKlang}
        zoom={11}
        maxZoom={20}
        minZoom={11}
        className="z-0 flex-grow flex"
        maxBoundsViscosity={1}
        maxBounds={outerBounds}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <div className="leaflet-top leaflet-right">
          <div className="leaflet-control">{renderDays()}</div>
        </div>
        {/* <div className="leaflet-top leaflet-right"> */}
        <div className="leaflet-bottom leaflet-right pb-5">
          <div className="leaflet-control">{renderPollutants()}</div>
        </div>
      </MapContainer>
      {renderLegend()}
    </div>
  );
}
