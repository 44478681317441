import React from "react";
import KlangMap from "components/map/KlangMap";

function MobileMap() {
  return (
    <div className="bg-none">
      <KlangMap isMobile={true} />
    </div>
  );
}

export default MobileMap;
