export function mapValues(pollutantType) {
  switch (pollutantType) {
    case "NO2":
      return mapNO2Values;
    case "NOx":
      return mapNOxValues;
    case "O3":
      return mapO3Values;
    case "PM2.5":
      return mapPM2_5Values;
    case "PM10":
      return mapPM10Values;
    case "SO2":
      return mapSO2Values;
    case "VOC":
      return mapVOCValues;
    default:
      console.error("Pollutant Type Invalid");
      return (values) => {
        return "000000";
      };
  }
}

export function mapNO2Values(values) {
  if (values[0] == -999) return null;
  if (values[0] > 225) return "#d7191c";
  if (values[0] > 200) return "#e85b3b";
  if (values[0] > 175) return "#f99d59";
  if (values[0] > 100) return "#fec980";
  if (values[0] > 150) return "#ffedaa";
  if (values[0] > 125) return "#ecf7b9";
  if (values[0] > 75) return "#c7e8ad";
  if (values[0] > 50) return "#9dd3a6";
  if (values[0] > 25) return "#64abb0";
  if (values[0] > 0) return "#2b83ba";
  return null;
}

export function mapNOxValues(values) {
  if (values[0] == -999) return null;
  if (values[0] > 1500) return "#d7191c";
  if (values[0] > 1400) return "#e1412e";
  if (values[0] > 1300) return "#eb6841";
  if (values[0] > 1200) return "#f59053";
  if (values[0] > 1100) return "#fdb367";
  if (values[0] > 1000) return "#fec980";
  if (values[0] > 900) return "#fedf99";
  if (values[0] > 800) return "#fff4b2";
  if (values[0] > 700) return "#f4fabb";
  if (values[0] > 600) return "#ddf1b4";
  if (values[0] > 500) return "#c7e8ad";
  if (values[0] > 400) return "#b1dfa6";
  if (values[0] > 300) return "#91cba8";
  if (values[0] > 200) return "#6fb3ae";
  if (values[0] > 100) return "#4d9bb4";
  if (values[0] > 0) return "#2b83ba";
  return null;
}

export function mapO3Values(values) {
  if (values[0] == -999) return null;
  if (values[0] > 120) return "#d7191c";
  if (values[0] > 110) return "#e44b33";
  if (values[0] > 100) return "#f07c4a";
  if (values[0] > 90) return "#fdae61";
  if (values[0] > 80) return "#fec980";
  if (values[0] > 70) return "#fee4a0";
  if (values[0] > 60) return "#ffffbf";
  if (values[0] > 50) return "#e3f4b6";
  if (values[0] > 40) return "#c7e8ad";
  if (values[0] > 30) return "#abdda4";
  if (values[0] > 20) return "#80bfab";
  if (values[0] > 10) return "#56a1b3";
  if (values[0] >= 0) return "#2b83ba";
  return null;
}

export function mapPM2_5Values(values) {
  if (values[0] == -999) return null;
  if (values[0] > 110) return "#d7191c";
  if (values[0] > 100) return "#e54f35";
  if (values[0] > 90) return "#f3854e";
  if (values[0] > 80) return "#fdb56a";
  if (values[0] > 70) return "#fed38c";
  if (values[0] > 60) return "#fff0ae";
  if (values[0] > 50) return "#f0f9ba";
  if (values[0] > 40) return "#d1ecb0";
  if (values[0] > 30) return "#b3e0a6";
  if (values[0] > 20) return "#88c4aa";
  if (values[0] > 10) return "#5aa4b2";
  if (values[0] >= 0) return "#2b83ba";
  return null;
}

export function mapPM10Values(values) {
  if (values[0] == -999) return null;
  if (values[0] > 150) return "#d7191c";
  if (values[0] > 140) return "#e1412e";
  if (values[0] > 130) return "#eb6841";
  if (values[0] > 120) return "#f59053";
  if (values[0] > 110) return "#fdb367";
  if (values[0] > 100) return "#fec980";
  if (values[0] > 90) return "#fedf99";
  if (values[0] > 80) return "#fff4b2";
  if (values[0] > 70) return "#f4fabb";
  if (values[0] > 60) return "#ddf1b4";
  if (values[0] > 50) return "#c7e8ad";
  if (values[0] > 40) return "#b1dfa6";
  if (values[0] > 30) return "#91cba8";
  if (values[0] > 20) return "#6fb3ae";
  if (values[0] > 10) return "#4d9bb4";
  if (values[0] >= 0) return "#2b83ba";
  return null;
}

export function mapSO2Values(values) {
  if (values[0] == -999) return null;
  if (values[0] > 210) return "#d7191c";
  if (values[0] > 200) return "#de3529";
  if (values[0] > 190) return "#e55236";
  if (values[0] > 180) return "#ed6e43";
  if (values[0] > 170) return "#f48b51";
  if (values[0] > 160) return "#fba75e";
  if (values[0] > 150) return "#fdba6e";
  if (values[0] > 140) return "#fec980";
  if (values[0] > 130) return "#fed892";
  if (values[0] > 120) return "#fee8a4";
  if (values[0] > 110) return "#fff7b6";
  if (values[0] > 100) return "#f7fcbc";
  if (values[0] > 90) return "#e7f5b7";
  if (values[0] > 80) return "#d7efb2";
  if (values[0] > 70) return "#c7e8ad";
  if (values[0] > 60) return "#b7e2a8";
  if (values[0] > 50) return "#a5d9a5";
  if (values[0] > 40) return "#8dc8a9";
  if (values[0] > 30) return "#74b6ad";
  if (values[0] > 20) return "#5ca5b2";
  if (values[0] > 10) return "#4394b6";
  if (values[0] >= 0) return "#2b83ba";
  return null;
}

export function mapVOCValues(values) {
  if (values[0] == -999) return null;
  if (values[0] > 4000) return "#d7191c";
  if (values[0] > 3800) return "#df372a";
  if (values[0] > 3600) return "#e65538";
  if (values[0] > 3400) return "#ee7245";
  if (values[0] > 3200) return "#f59053";
  if (values[0] > 3000) return "#fdae61";
  if (values[0] > 2800) return "#fdbe74";
  if (values[0] > 2600) return "#fece87";
  if (values[0] > 2400) return "#fedf99";
  if (values[0] > 2200) return "#ffefac";
  if (values[0] > 2000) return "#ffffbf";
  if (values[0] > 1800) return "#eef8ba";
  if (values[0] > 1600) return "#ddf1b4";
  if (values[0] > 1400) return "#cdebaf";
  if (values[0] > 1200) return "#bce4a9";
  if (values[0] > 1000) return "#abdda4";
  if (values[0] > 800) return "#91cba8";
  if (values[0] > 600) return "#78b9ad";
  if (values[0] > 400) return "#5ea7b1";
  if (values[0] > 200) return "#4595b6";
  if (values[0] >= 0) return "#2b83ba";
  return null;
}
