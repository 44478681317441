import React, { useState } from "react";
import { Button } from "react-daisyui";
import { Link } from "react-router-dom";

function PublicNavbar(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function navButton(name, path) {
    return (
      <Link to={path ?? ""}>
        <Button className="h-10 w-full rounded-none border-0 bg-opacity-0 text-lg font-normal text-black hover:bg-gray-200 lg:h-20 lg:w-32">
          {name}
        </Button>
      </Link>
    );
  }

  React.useEffect(() => {}, []);

  return (
    <div className="sticky -top-1 z-50 w-full items-center border-b-2 border-b-gray-300 bg-white bg-opacity-95 lg:flex lg:justify-between lg:px-10">
      <div>
        <div className="grid grid-cols-2 lg:grid-cols-4">
          <div className={"col-span-1 items-center"}>
            {navButton("HOME", "/home")}
          </div>
          <div className={"col-span-1 items-center"}>
            {navButton("ABOUT US", "/")}
          </div>
          <div className={"col-span-1 items-center"}>
            {navButton("DATA", "/")}
          </div>
          <div className={"col-span-1 items-center"}>
            {navButton("SIGN IN", "/auth/login")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicNavbar;
