const keyLabelPatientInfo = [
  {
    type: "text",
    key: "name",
    label: "First Name",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "number",
    key: "age",
    label: "Age",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "text",
    key: "mobile",
    label: "Mobile Number",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "text",
    key: "email",
    label: "Email",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "textarea",
    key: "address",
    label: "Address",
    size: 12,
    disable: false,
    required: false,
  },
  {
    type: "textarea",
    key: "remarks",
    label: "Remarks",
    size: 12,
    disable: false,
    required: false,
  },
];

export default {
  keyLabelPatientInfo,
};
