import top from "../../images/top.jpg";

import { Row, Col } from "components/CustomComponents";
import React, { useState } from "react";
import { Avatar, Button, Dropdown, Menu } from "react-daisyui";
import { FaBook, FaPerson } from "react-icons/fa6";
import {
  IoBusiness,
  IoHomeOutline,
  IoLogOutOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

export default function DoctorHeader() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [userObj, setUserObj] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  return (
    <div className="relative">
      <img
        src={top}
        sizes="100vw"
        style={{
          objectFit: "cover",
          width: "100%",
          height: 350,
          objectPosition: "50% 75%",
        }}
        alt=""
      />
    </div>
  );
}
