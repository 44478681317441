import React from "react";
import Base from "./basePatient";

function CreatePatient(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [forward, setForward] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  React.useEffect(() => {
    if ("forward" in props) {
      setForward(props.forward);
    }
  }, []);

  if (forward !== null) return <Base forward={forward} />;
  else return <Base />;
}

export default CreatePatient;
