import React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

// custom library imports for page
import { httpRequest } from "helpers/httpClient/httpClient";
import { onResponse } from "helpers/httpClient/onResponse";
import { validateEmail } from "helpers/validator";
import { CustomButton } from "components/CustomComponents";

// external libraries imports
import { Button, Form, Checkbox } from "react-daisyui";
import toast, { Toaster } from "react-hot-toast";

function Login(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const navigate = useNavigate();

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function doSubmit() {
    var error = false;

    if (!email || email === "") {
      toast("Please enter email to log in!");
      error = true;
    }
    if (!password || password === "") {
      toast("Please enter password to log in!");
      error = true;
    }

    if (error) return;

    if (!validateEmail(email.trim())) {
      toast("Please enter a valid email!");
      error = true;
    }

    if (error) return;

    if (!error) {
      httpRequest(
        "/auth/login",
        "POST",
        {
          email: email.trim(),
          password: password,
        },
        doSubmitCB.bind(this)
      );
    }
  }

  function doSubmitCB(jsonObj) {
    toast(onResponse(jsonObj.code, jsonObj.message));

    if (jsonObj.success) {
      jsonObj.data.token = jsonObj.data.token[0];
      localStorage.setItem("userObject", JSON.stringify(jsonObj.data));
      navigate("/doctor/patient");
    }
  }

  React.useEffect(() => {}, []);

  return (
    <>
      <div className="relative flex h-screen w-full items-center bg-gray-400 bg-opacity-70">
        <div className="m-auto h-fit w-[32rem] overflow-y-auto rounded-3xl bg-white p-12 text-center shadow-xl max-xl:w-[25rem] max-xl:p-8">
          <div className="mb-7 text-3xl font-bold">
            <span>Respire</span>
            <span className="text-blue-600">Malaysia</span>
          </div>

          <h1 className="text-xl font-bold">Sign In</h1>

          <Form
            className="text-lg"
            onSubmit={(e) => {
              e.preventDefault();
              doSubmit();
            }}
          >
            <div className="mb-7 mt-5">
              <label className="mb-3 block text-start font-bold text-gray-700">
                Email Address
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded-lg border border-gray-200 px-4 py-3 leading-tight text-gray-700 shadow-lg focus:outline-none"
                type="text"
                name="email"
                placeholder="user@gmail.com"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div className="mb-7">
              <label className="mb-3 block text-start font-bold text-gray-700">
                Password
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded-lg border border-gray-200 px-4 py-3 leading-tight text-gray-700 shadow-lg focus:outline-none"
                type="password"
                value={password}
                placeholder="*************"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>

            {/* <div className="mb-10 flex items-center justify-between max-xl:mb-5 max-xl:text-base">
              <div className="flex items-center">
                <Checkbox className="mr-2 cursor-pointer" size="sm" />
                <p>Remember Me</p>
              </div>

              <p className="cursor-pointer text-blue-500 hover:underline">
                    Forget Password?
                  </p>
            </div> */}
            <CustomButton
              className="w-full bg-blue-500 text-lg hover:bg-blue-600 text-white"
              type="submit"
              size={"md"}
              onClick={(e) => {
                e.preventDefault();
                doSubmit();
              }}
            >
              Sign In
            </CustomButton>
            <div className="h-2" />
            <CustomButton
              className="w-full bg-red-500 text-lg hover:bg-red-600 text-white"
              type="submit"
              size={"md"}
              onClick={(e) => {
                e.preventDefault();
                var userObj = JSON.parse(localStorage.getItem("userObject"));
                userObj.token = null;
                localStorage.setItem("userObject", JSON.stringify(userObj));
                navigate("/home");
              }}
            >
              Back to Home Page
            </CustomButton>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
