import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "components/CustomComponents";
import { Avatar, Button, Dropdown, Menu } from "react-daisyui";
import { FaBook, FaPerson } from "react-icons/fa6";
import {
  IoBusiness,
  IoHomeOutline,
  IoLogOutOutline,
  IoNotificationsOutline,
} from "react-icons/io5";

function DoctorNavbar(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [userObj, setUserObj] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  React.useEffect(() => {
    var userObject = JSON.parse(localStorage.getItem("userObject"));
    if (userObject) {
      setUserObj(userObject);
    }
  }, []);

  return (
    <div className="h-20 sticky -top-1 z-50 w-full items-center border-b-2 border-b-gray-300 bg-white bg-opacity-95 lg:flex lg:justify-between lg:px-10">
      <div className="flex">
        <div className="dropdown dropdown-end z-[999]">
          <label
            tabIndex={0}
            className="mr-3 flex items-center text-lg font-medium"
          >
            {userObj
              ? `Welcome Dr. ${userObj.lastName ?? userObj.firstName ?? userObj.name ?? ""}!`
              : "Welcome Dr.!"}
          </label>
          <ul
            tabIndex={0}
            className="menu-normal menu dropdown-content rounded-box mt-3 w-56 bg-white p-2 text-sm shadow"
          >
            <li>
              <a className="text-sm">Profile</a>
            </li>
            <li>
              <a className="justify-between text-sm">Settings</a>
            </li>
            <li>
              <Link href={"/home"}>
                <button className="text-sm">Sign Out</button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-10">
        <Col colSize={1}>
          <Link to={"/doctor/patient/list"}>
            <IoHomeOutline size={24} />
          </Link>
        </Col>
        <Col colSize={1}>
          <div className="dropdown dropdown-end z-[999]">
            <IoNotificationsOutline size={24} tabIndex={1} />
            <ul
              tabIndex={1}
              className="dropdown-content rounded-box mt-3 w-80 bg-white text-sm shadow"
            >
              <li>
                <div className="py-3 text-center font-bold">
                  <h6 className="text-muted m-0 text-sm">
                    You have<span className="text-info">{" 8 "}</span>
                    notifications.
                  </h6>
                </div>
              </li>
              <li>
                <hr />
              </li>
              <li>
                <Menu className="m-0 p-0">
                  <Menu.Item className="rounded-none">
                    <a>
                      <FaPerson className="h-6 w-6" /> Afiq has registered to
                      become a merchant
                    </a>
                  </Menu.Item>
                  <Menu.Item className="rounded-none">
                    <a>
                      <FaBook className="h-6 w-6" /> 14 Reviews has been flagged
                      as innapropriate
                    </a>
                  </Menu.Item>
                  <Menu.Item className="rounded-none">
                    <a>
                      <IoBusiness className="h-6 w-6" /> A new business has been
                      approved
                    </a>
                  </Menu.Item>
                </Menu>
              </li>
              <li>
                <hr />
              </li>
              <li>
                <a className="hover:cursor-pointer">
                  <div className="py-3 text-center font-bold text-info">
                    View all
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </Col>
        <Col colSize={1}>
          <Link to={"/home"}>
            <IoLogOutOutline size={24} />
          </Link>
        </Col>
      </div>
    </div>
  );
}

export default DoctorNavbar;
