// basic imports
import React from "react";

// custom library imports for page
import DaisyUITable from "components/DaisyUITable";
import SimpleForm from "components/SimpleForm/SimpleForm.js";
import { httpRequest } from "helpers/httpClient/httpClient";
import { onResponse } from "helpers/httpClient/onResponse";
import YesNoModal from "components/YesNoModal/YesNoModal";
import View from "./viewPatient";
import Create from "./createPatient";
import {
  Row,
  Col,
  CustomModal,
  CustomButton,
} from "components/CustomComponents";

// external libraries imports
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Progress,
  Input,
  InputGroup,
  Modal,
} from "react-daisyui";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import TimeAgo from "javascript-time-ago";
import { MdAdd, MdDelete, MdEdit, MdMonitor } from "react-icons/md";
import { FaHeartCirclePlus, FaRotate } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { GiHealthNormal, GiHeartPlus } from "react-icons/gi";
import { BsClipboard2PlusFill } from "react-icons/bs";

function ListAllPatient() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [callAPIReady, setCallAPIReady] = React.useState(false);

  const [pageState, setPageState] = React.useState(1);
  const [totalSize, setTotalSize] = React.useState(0);
  const [sizePerPageState, setSizePerPageState] = React.useState(50);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [forward, setForward] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [yesNoModal1Forward, setYesNoModal1Forward] = React.useState(null);
  const [yesNoModal1Show, setYesNoModal1Show] = React.useState(null);
  const [id, setId] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const timeAgo = new TimeAgo("en-US");
  const columns = [
    {
      dataField: "nameCol",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    // {
    //   dataField: "healthStatus",
    //   text: "Health Status",
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  const form = [
    {
      size: "12",
      name: "Search",
      type: "text",
      value: search,
      onChange: (e) => {
        setSearch(e.target.value);
      },
    },
  ];

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function callAPI(_sizePerPage, _currentIndex) {
    httpRequest(
      "/patient/list",
      "POST",
      {
        search: search,
        currentIndex: _currentIndex,
        sizePerPage: _sizePerPage,
      },
      listCB.bind(this)
    );
  }

  function handleTableChange(page, sizePerPage) {
    var currentIndex = (page - 1) * sizePerPage;
    setCurrentIndex(currentIndex);
    setPageState(page);
    setSizePerPageState(sizePerPage);
    setCallAPIReady(true);
  }

  function doSubmit() {
    setPageState(1);
    setCurrentIndex(0);
    setCallAPIReady(true);
  }

  function doReset() {
    setSearch("");
    setCallAPIReady(true);
  }

  function onCreate() {
    setForward({
      cb: () => {
        setCallAPIReady(true);
        setCreateModalOpen(false);
      },
    });
    setCallAPIReady(true);
    setCreateModalOpen(true);
  }

  function onView(_id) {
    setForward({
      _id: _id,
      cb: () => {
        setCallAPIReady(true);
        setModalOpen(false);
      },
    });
    setId(_id);
    setModalOpen(true);
  }

  function onDelete(_id) {
    setId(_id);
    setYesNoModal1Show(true);
    setYesNoModal1Forward({
      show: true,
      text: "Are you sure you want to delete this patient?",
      cb: (ret) => {
        if (ret === true) {
          httpRequest(
            "/patient/delete",
            "DELETE",
            {
              _id: _id,
            },
            (jsonObj) => {
              toast(onResponse(jsonObj.code, jsonObj.message));
              setCallAPIReady(true);
            }
          );
        }
        setYesNoModal1Show(false);
      },
    });
  }

  function listCB(jsonObj) {
    if (jsonObj.success === true) {
      setTotalSize(jsonObj.data.count);

      for (var i = 0; i < jsonObj.data.doc.length; i++) {
        var doc = jsonObj.data.doc[i];
        jsonObj.data.doc[i].nameCol = (
          <>
            <div>
              <span style={{ whiteSpace: "pre-line" }}>
                {doc.name && doc.name !== "" ? doc.name : "-"}
              </span>
            </div>
          </>
        );

        //Action
        const _id = jsonObj.data.doc[i]._id;
        jsonObj.data.doc[i].action = (
          <div>
            <CustomButton
              message={"Edit details"}
              className={
                "h-11 rounded-full border-none bg-transparent text-blue-600 hover:bg-blue-100"
              }
              onClick={(e) => {
                e.preventDefault();
                onView(_id);
              }}
            >
              <MdEdit size={23} />
            </CustomButton>
            <CustomButton
              message={"Asthma Condition"}
              className={
                "h-11 rounded-full border-none bg-transparent text-sky-600 hover:bg-sky-100"
              }
              onClick={(e) => {
                e.preventDefault();
                toast("Pending Asthma Condition");
              }}
            >
              <GiHealthNormal size={23} />
            </CustomButton>
            <CustomButton
              message={"Asthma Monitoring"}
              className={
                "h-11 rounded-full border-none bg-transparent text-green-600 hover:bg-green-100"
              }
              onClick={(e) => {
                e.preventDefault();
                toast("Pending Asthma Monitoring");
              }}
            >
              <MdMonitor size={23} />
            </CustomButton>
            <CustomButton
              message={"Delete"}
              className={
                "h-11 rounded-full border-none bg-transparent text-red-500 hover:bg-red-100"
              }
              onClick={(e) => {
                e.preventDefault();
                onDelete(_id);
              }}
            >
              <MdDelete size={23} />
            </CustomButton>
          </div>
        );
      }

      setData(jsonObj.data.doc);
    } else {
      toast(onResponse(jsonObj.code, jsonObj.message));
    }
  }

  React.useEffect(() => {
    setCallAPIReady(true);
  }, []);

  if (callAPIReady) {
    setCallAPIReady(false);
    callAPI(sizePerPageState, currentIndex);
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="mb-0">List Patients</h3>
          </Card.Title>

          <div className="mt-5 flex items-center">
            <div className="mr-4 w-full">
              {form.map((item, key) => {
                return (
                  <SimpleForm
                    key={key}
                    size={item.size}
                    type={item.type}
                    value={item.value}
                    placeholder={item.name}
                    onChange={item.onChange}
                    className={"border-none bg-gray-100 px-4 py-5 outline-none"}
                  />
                );
              })}
            </div>

            <div className="flex items-center justify-center">
              <CustomButton
                message={"Search"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-black"
                }
                onClick={(e) => {
                  e.preventDefault();
                  doSubmit();
                }}
              >
                <IoSearch size={20} />
              </CustomButton>

              <CustomButton
                message={"Reset"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-black"
                }
                onClick={(e) => {
                  e.preventDefault();
                  doReset();
                }}
              >
                <FaRotate size={18} />
              </CustomButton>

              <CustomButton
                message={"Add new"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-green-500 hover:bg-green-100"
                }
                onClick={(e) => {
                  e.preventDefault();
                  onCreate();
                }}
              >
                <MdAdd size={23} />
              </CustomButton>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <DaisyUITable
          columns={columns}
          data={data}
          page={pageState}
          sizePerPage={sizePerPageState}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      </Card>

      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={"View Patient Details"}
      >
        {id !== null && modalOpen ? <View forward={forward} /> : <div />}
      </CustomModal>
      <CustomModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title={"Create Patient"}
      >
        {createModalOpen ? <Create forward={forward} /> : <div />}
      </CustomModal>
      <YesNoModal forward={yesNoModal1Forward} show={yesNoModal1Show} />
      <Toaster />
    </div>
  );
}

export default ListAllPatient;
